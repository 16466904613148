.features {
  padding-bottom: 2.5rem;
  padding-left: 2.5vw;
  margin-left: -5vw;
  padding-right: 2.5vw;
  margin-right: -5vw;
  border-radius: 20px;

  ul {
    margin-top: 5rem;
    padding: 0;
  }
}

.days-saved {
  box-shadow: 0 0 200px rgba($purple, 0.3);
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;

  padding-left: 7.5vw;
  margin-left: -7.5vw;
  padding-right: 7.5vw;
  margin-right: -7.5vw;
  border-radius: 20px;
  text-align: center;

  h2 {
    margin-top: 3rem;
  }
}

.download {
  margin-top: 10rem;
  flex-wrap: wrap;
  position: relative;
}

.home {
  text-align: center;
}
