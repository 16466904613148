.steps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .step {
    display: flex;
    align-items: center;
    position: relative;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2.5vh;

    &--left {
      justify-content: flex-start;
    }

    &--right {
      justify-content: flex-end;

      .copy:before {
        left: auto;
        right: -10vw;
      }
    }

    &:first-child {
      margin-top: -10px;
    }

    h1 {
      margin-bottom: 0;
    }

    h2 {
      margin-top: 5px;
    }

    .copy {
      position: relative;

      &:before {
        position: absolute;
        left: -10vw;
        top: 0;
        font-size: 25vw;
        font-weight: 800;
        color: black;
        opacity: 0.4;
        display: block;
        z-index: -1;
      }
      &:hover:before {
        opacity: 1;
      }
    }

    @for $i from 1 through 10 {
      &:nth-of-type(#{$i}) .copy:before {
        content: '#{$i}';
        opacity: 0.05 + (($i/10) * 0.2);
      }
    }
  }
}


.arrow {
  display: none;
  font-weight: 700;
  font-size: 7vw;
  padding: 0 20px;
}

.step--right .arrow {
  transform: scale(-1, 1);
}
