@import url('https://fonts.googleapis.com/css?family=Raleway:200,300');

$border-width: 5px;

button {
  font-size: calc(0.5vw + 10px);
  border: none;
  border: $border-width solid rgba(white, 1);
  border-radius: 125px;
  padding: 10px 20px;
  letter-spacing: 1px;
  background: transparent;
  color: white;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  outline: none;
  text-decoration: none;

  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 0;
    height: 0;
    filter: blur(20px);
    background: linear-gradient( 0deg, $blue, $green );
    width: calc(100% + 40px );
    height: calc(100% + 40px );
    top: -20px;
    left: -20px;
    border-radius: 125px;
    z-index: -2;
    pointer-events: none;
    opacity: 0;
    transition: 200ms;
  }

  &:after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient( -120deg, rgba($blue, 1), rgba($green,1) );
    top: 0;
    left: 0;
    border-radius: 125px;
    z-index: -1;
  }

  &.purple {
    &:before {
      background: linear-gradient( 120deg, $purple, $green );
    }

    &:after {
      background: linear-gradient( -120deg, rgba($purple, 1), rgba($green,1) );
    }
  }

  &:hover {
    transition:  200ms;
    background: rgba(white, 0.5);

  }

  &:hover:after  {
    //opacity: 0.5;
  }


  &.large {
    font-size: calc(1vw + 10px);
    padding: 15px 30px;
  }

  &.inverted {
    cursor: not-allowed;
    background: white;
    color: $blue;

    &:before {
      transition: 1000ms;
      opacity: 1;
    }
  }

  &[disabled] {
    opacity: 0.5;
  }
  &[disabled]:after {
    cursor: not-allowed;
  }
}