@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInOne {
  0% { opacity: 0; }
  100% { opacity: 0.2; }
}

@keyframes fadeInTwo {
  0% { opacity: 0; }
  100% { opacity: 0.4; }
}

@keyframes fadeInThree {
  0% { opacity: 0; }
  100% { opacity: 0.6; }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.page-appear {
  animation: fadeIn 750ms ease-out both;
}