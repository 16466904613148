@import url('https://fonts.googleapis.com/css?family=Knewave');

@import "libs/normalize.css";

@import "variables";
@import "elements";
@import "animations";
@import "pages";

@import "components/navigation";
@import "components/steps";
@import "components/button";
@import "components/picture";
@import "components/slider";

img.logo {
  max-width: 300px;
  overflow: visible;
}

.logo {
  img {
    max-height: 75px;
    width: auto;
    margin-right: 1rem;
  }
  flex-direction: row;
  display: flex;
  align-items: center;
}

path,
rect,
circle {
  fill: #fff;
}

.knee {
  font-weight: 100;
  font-family: 'Knewave', cursive;
}

h2.knee {
  text-shadow: 0 0 20px $blue !important;
}

.never {
  max-width: 500px;
  position: absolute;
  transform: rotate(-10deg);
  left: -5rem;
  text-shadow: 0 0 20px red;
  top: -2rem;

  @media (max-width: $small-screen) {
    top: -3.5rem;
    transform: rotate(-5deg);
    left: -2rem;
  }
}

.never-bottom {
  max-width: 500px;
  position: absolute;
  transform: rotate(-10deg);
  right: -5rem;
  text-shadow: 0 0 20px red;
  bottom: -2rem;

  @media (max-width: $small-screen) {
    bottom: -2.5rem;
    transform: rotate(-5deg);
    right: -2rem;
  }
}

.features {
  margin-top: 7.5rem;
}

.download.first {
  margin-top: 5rem;
}

.demo {
  margin-top: 5vh;
  text-align: center;
  position: relative;
  h1 {
  }

  img {
    border-radius: 20px;
    box-shadow: 0 0 200px rgba($purple, 0.5);
    width: 100%;
    max-width: 550px;

    @media (max-width: $small-screen) {
      object-fit: cover;
      object-position: top right;
      height: 425px;
      border-radius: 0;
    }
  }
}

.features,
.demo {
  h1 {
    text-align: center;
  }
}

.testimonials {
  display: none;
  width: 100%;
  background: white;
}

.download {
  text-align: center;
  justify-content: center;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
}

ul.cards {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

  li {
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 300px;
    padding: 2rem;
    min-width: 300px;

    h1 {
      text-shadow: 0 0 10px rgba($blue, 0.7);
      margin: 0 0;
    }

    h2 {
      text-shadow: 0 0 10px rgba($blue, 0.7);
      line-height: 3rem;
      margin: 0 0;
    }

    p {
      margin: 0.5rem 0;
      color: white;
      text-align: left;
      font-size: 1.25rem;
      font-weight: 100;
      line-height: 1.5rem;
      font-family: 'Raleway', sans-serif;
      font-weight: 300;

      &.emoji {
        position: absolute;
        font-size: 6rem;
        margin-top: -1rem;
        margin-left: -2rem;
        opacity: 0.3;
        z-index: -1;
        transform: rotate(-20deg);
      }
    }

    @media (max-width: $small-screen) {
      align-items: center;
      justify-content: center;

      p {
        text-align: center;

        &.emoji {
          margin-top: -3rem;
          margin-left: 0rem;
        }
      }
    }
  }
}
