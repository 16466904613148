
.picture {
  margin-top: 25px;
  width: 400px;
  max-width: 100%;
  min-height: 300px;


  background-size: cover;
  background-position: center;
  border-bottom: 10px solid white;
  border-right: 10px solid white;
  color: black;
  position: relative;
  box-shadow: -15px -15px 0px black;
  transition: 200ms;
  cursor: pointer;
  z-index: 1;

  .copy {
    position: absolute;
    top: -60px;
    left: 0px;
  }

  .title {
    line-height: 30px;
    padding: 10px;
    font-size: 25px;
    font-weight: 40px;
    color: white;
    background: white;
    color: black;
    font-weight: 800;
    display: inline-block;
    text-transform: uppercase;
  }

   .sub-title {
    display: inline-block;
    line-height: 30px;
    padding: 10px;
    font-size: 25px;
    font-weight: 40px;
    color: white;
    background: white;
    color: black;
    font-weight: 800;
  }

  &:after {

  }
  &:hover {
    transform: translateZ(10px);
  }

  &:active:after {
    transition: 200ms;
    border-top: 2px solid rgba(black, 1);
    border-left: 2px solid rgba(black, 1);
  }


  &.inverted {
    background: white;
    color: black;
  }

  &.blue {
    box-shadow: -15px -15px 0px black;
    color: white;

    .title, .sub-title {
      color: black;
    }
  }
}
