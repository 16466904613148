body {
  overflow-x: hidden;
  font-family: 'Raleway', sans-serif;
  color: white;
  position: relative;
  z-index: 1;

  &:before {
    background: linear-gradient(120deg, $blue, $green);
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: block;
    content: '';
    will-change: transform;
    transform: translateZ(0);
  }
}

.container {
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: left;
  position: relative;
  z-index: 0;
}

header {
  width: 80vw;
  margin-left: 10vw;
  margin-right: 10vw;
  max-width: 940px;

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    ul {
      display: flex;
      padding: 0;
      list-style-type: none;
      justify-content: center;
      align-items: center;

      li {
        margin: 1rem;
      }
    }

    svg {
      height: 25px;
      width: auto;
    }
  }

  .top-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
  }
}

main {
  position: relative;
  margin-top: 2.5vh;
  z-index: 0;
  & > section {
    animation: fadeIn 750ms ease-out both;
    position: relative;

    width: 80vw;
    margin-left: 10vw;
    margin-right: 10vw;
    max-width: 800px;

    @media (max-width: $small-screen) {
    }
  }
}

h1 {
  font-size: calc(2.25vw + 1.5rem);

  &.title {
    text-align: center;
    margin-bottom: 0;
  }
}

h2 {
  font-size: calc(1.25vw + 1rem);
  margin-top: 0.5rem;

  &.sub-title {
    text-align: center;
  }
}

h3 {
  &.purple-bg {
    background: linear-gradient(to right, #bf00ba 0%, #f244ac 100%);
  }
}

p {
  padding: 10px 0;
}

a {
  text-decoration: none;
  color: inherit;

  &:not(.no-border) {
    border-bottom: 1px dotted white;
  }
}

b {
  font-weight: 700;
  border-bottom: 2px dotted white;
}

input {
  background-color: transparent;
  border: none;
  border-radius: 0px;
  color: white;
  font-size: calc(1vw + 0.75rem);
  border-left: 2px dashed white;
  border-bottom: 2px dashed white;
  line-height: calc(2vw + 2rem);
  width: 75%;
  padding-left: calc(0.5vw + 0.75rem);
  font-weight: bold;
  outline: none;
  z-index: 2;
  position: relative;

  &:not(:focus):valid {
    border-left: 5px solid $green;
  }

  &:focus {
    color: $blue;
    background-color: white;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
  }

  &::placeholder {
    color: white;
    font-weight: bold;
    overflow: visible;
  }

  &:focus::placeholder {
    color: rgba($blue, 0.5);
    font-weight: bold;
  }
}

details {
  margin-top: 1rem;
  summary {
    cursor: zoom-in;
    outline: none;
    font-size: calc(1vw + 0.75rem);
    user-select: none;
  }

  &[open] summary {
    cursor: zoom-out;
  }

  p {
    margin: 1rem 0 1rem 1rem;
    font-size: calc(0.75vw + 0.75rem);
  }
  img {
    max-width: 80vw;
  }
}

footer {
  margin-top: 10vh;
}
