.sidebar-button {
  display: none;
  font-size: 1rem;
  font-weight: 800;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1337;
  min-width: 75px;

  @media (max-width: $small-screen) {
    display: inline-block;
  }
}

body main,
body header > *:not(nav):not(.sidebar-button) {
  transition: all 200ms;
}

body[data-sidebar-open="true"] {
  overflow: hidden;

  & main,
  nav figure {
    filter: blur(20px);
  }
}


nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 1337;

  // a[href="/"] {
  //   display: none;
  // }

  // @media (max-width: $small-screen) {
  //   a[href="/"] {
  //     display: block;
  //   }

  // }

  button {
    //margin-right: calc(2.5vw + 10px);
    // border-radius: 0;
  }

  // a:first-child button {
  //    border-radius: 20px 0 0 0;
  // }

  // a:first-child button {
  //    border-radius: 20px 0 0 0;
  // }

  // a:last-child button {
  //    border-radius: 0 20px  0 0;
  // }


  a:last-child button {
    margin-right: 0;
  }

  @media (max-width: $small-screen) {
    flex-direction: column;
    .logo {
      margin: 2.5rem 0;
    }

    ul {
      overflow: hidden;
      left: 0;
      bottom: 0px;
      justify-content: flex-end;
      width: 100vw;
      height: 100vh;
      flex-direction: column;
      position: fixed;
      transition: all 400ms ease-in-out;
      opacity: 0;
      overflow: visible;
      pointer-events: none;


      body[data-sidebar-open="true"] & {
        pointer-events: all;
        z-index: 1;
        opacity: 1;
      }
    }

    & a {
      display: block;
      width: 80%;
      margin-top: 20px;
      button {
        width: 100%;
      }
    }
  }
}


nav.mobile {

}